import * as peerRegistry from "./peer-registry.js"
import * as z from "zod"
import chunk from "lodash/chunk"
import isNil from "lodash/isNil"
import omit from "lodash/omit"
import * as uuid from "uuid"
import sortedUniq from "lodash/sortedUniq"
import isEmpty from "lodash/isEmpty"
import debounce from "lodash/debounce"
import queryString from "qs"
import isNumber from "lodash/isNumber"
import compact from "lodash/compact"
import sortBy from "lodash/sortBy"
import { CreateVersionMessage, ProjectUploadOutput, RemixCompleteRequest } from "@cocoplatform/coco-rtc-shared"
import {
    arrayBufferToBase64,
    base64ToArrayBuffer,
} from "./utils/buffer-helpers.js"
import {
    DataChannelMessages,
    ProjectPullRequest,
    ProjectPullResponse,
    ProjectUpdateNotification,
} from "./data-channel-handler.js"
import { canvas } from "./rtc-handlers.js"
import { actionEmitter, Dispatcher } from "./actions/index.js"
import { vm } from "./vm.js"
import {
    projectPeerPullSizeThreshold,
    projectServerPullTimeThreshold,
    supportProjectSaveToServer,
} from "./initial-search-params.js"
import {
    Deferred,
    ensureSeq,
    rejectAfter,
    retryOnErr,
    timeout,
} from "./utils/promise.js"
import { SnapshotUpdateMessage, VisitProjectMessage } from "@cocoplatform/coco-rtc-shared"
import { initialProjectDetailsDeferred } from "./rtc-handlers.js"
import { httpClient } from "./http-client.js"
import { ObservableMap } from "./utils/observable.js"
import { atom } from "./utils/store.js"
import isArray from "lodash/isArray"
import isObject from "lodash/isObject"
import isString from "lodash/isString"
import isEqual from "lodash/isEqual"
import { isBlocksSpace, isCanvasSpace, isCodeSpace } from "./space.js"
import { codeEditorStore, getCodeArchive, loadCodeArchive } from "./p5/state.js"
import {
    canvasEditorStore,
    getCanvasDataArchive,
    getCanvasManifest,
    loadCanvasDataArchive,
} from "./canvas-data-coordinator.js"
import {
    VersionCreatedMessage,
    VersionRejectedMessage,
    PromoteVersionMessage,
} from "@cocoplatform/coco-rtc-shared"
import axios from "axios"
import { CocoLogger } from "@cocoplatform/coco-logger"
import { sendWSMessage, sendWsMessageWithResponse, spaceId } from "./socket-client.js"

export const collaboratorIdToSnapshotMap = new ObservableMap<string, string>()

export const projectFileKey = "project.json"

let lastSuccessfullCollaboratorViewed: string | undefined = undefined;

const maxUploadAttempts = 3

const PATCH_TIMEOUT = 15000;
const FULL_UPDATE_TIMEOUT = 60000;

interface SnapshotCaptureOpts {
    broadcast?: boolean
    hideVideo?: boolean
}

const attemptSnapshotCapture = ensureSeq(
    async (opts?: SnapshotCaptureOpts): Promise<string | null> => {
        if (isBlocksSpace()) {
            // Scratch projects
            if (!vm?.renderer) {
                throw new Error("Renderer not available")
            }
            const snapshot = await Promise.race([
                timeout(500),
                new Promise<string | null>((resolve) => {
                    if (opts?.hideVideo) {
                        vm.postIOData("video", {
                            forceTransparentPreview: true,
                        })
                    }
                    vm.renderer.requestSnapshot((snapshot: string) => {
                        const drawList = vm.renderer._drawList;
                        const drawables = drawList.map((drawable: any) => {
                            return vm.renderer._allDrawables[drawable];
                        });
                        const videoDeviceId = vm.runtime.ioDevices.video?._drawable;
                        const isVideoEnabled = drawables.some((drawable: any) => {
                            return drawable?.id === videoDeviceId && drawable?._visible;
                        });

                        if (opts?.hideVideo) {
                            vm.postIOData("video", {
                                forceTransparentPreview: false,
                            })
                        }

                        if (isVideoEnabled) {
                            // If video is enabled, we don't want to capture the snapshot
                            // to avoid capturing the creator's video
                            // Although this should ideally not happen as we avoid capturing
                            // snapshots when video is enabled, but just in case we do
                            // capture a snapshot, we should discard it
                            return resolve(null);
                        }

                        resolve(snapshot)
                    })
                }),
            ])
            if (snapshot) {
                trackLocalSnapshot(snapshot)
            }
            return snapshot ?? null
        } else if (canvas) {
            const snapshot = canvas.toDataURL()
            trackLocalSnapshot(snapshot)
            return snapshot
        }
        return null
    }
)

export const captureSnapshot = async (opts?: SnapshotCaptureOpts) => {
    const image = await attemptSnapshotCapture(opts)
    if (image && opts?.broadcast && isVisitingSelf()) {
        sendWSMessage<SnapshotUpdateMessage>({
            type: "update-snapshot",
            id: peerRegistry.ensureCurrentPeerId(),
            image,
        })
    }
    return image
}

export const trackLocalSnapshot = (snapshot: string) => {
    const peerId = visitedCollaboratorId.get() || peerRegistry.currentPeerId
    if (!peerId) return
    if (peerId === peerRegistry.currentPeerId) {
        collaboratorIdToSnapshotMap.set(peerId, snapshot)
        /* emitSnapshotToPeers(snapshot)?.catch(e => {
            console.error(`Failed to emit snapshot to peers`, e)
        }) */
    }
}

/*
const chunkCountEmitThreshold = 30

const emitSnapshotToPeers = debounce(blockConcurrent(async (snapshot: string) => {
    const snapshotId = uuid.v4()
    const chunkSize = 200
    const chunkCount = Math.ceil(Math.max(1, snapshot.length / chunkSize))

    if (chunkCount > chunkCountEmitThreshold) {
        console.warn(`Bailing on snapshot update because chunkCount (${chunkCount}) exceeds threshold (${chunkCountEmitThreshold})`)
        return
    }

    for (let i = 0; i < chunkCount; i++) {
        sendMessageToAllDataChannels(
            DataChannelMessages.cocoSnapshotUpdate({
                id: peerRegistry.ensureCurrentPeerId(),
                snapshotId,
                chunkIdx: i,
                chunkCount,
                snapshot: snapshot.slice(i * chunkSize, (i + 1) * chunkSize),
            }),
            {
                awaitOpen: false,
                retryOnFailure: false,
                ensureSeq: false,
                excludeSelf: true
            }
        )
        await timeout(10)
    }
}), 1000) */

export const remixComplete = async (remixedFrom: string) => {
    if (!peerRegistry.currentPeerId || !spaceId) {
        throw new Error("Cannot remix because peer id or space id is not available")
    }

    await sendWsMessageWithResponse<RemixCompleteRequest>({
        type: "remix-complete",
        id: peerRegistry.currentPeerId,
        spaceId,
        messageId: uuid.v4(),
        remixedFrom,
    });
}

export const captureSnapshotOrIgnore = async (opts?: SnapshotCaptureOpts) => {
    try {
        return await captureSnapshot(opts)
    } catch (e) {
        console.error("Failed to capture snapshot", e)
    }
}

export const getLastSnapshot = async (
    peerId = visitedCollaboratorId.get() || peerRegistry.currentPeerId
) => {
    return peerId ? collaboratorIdToSnapshotMap.get(peerId) : undefined
}

export const getLatestSnapshot = async (
    peerId = visitedCollaboratorId.get() || peerRegistry.currentPeerId
) => {
    await captureSnapshotOrIgnore()
    return peerId ? collaboratorIdToSnapshotMap.get(peerId) : undefined
}

export const waitForSnapshot = async (peerId: string) => {
    for (let i = 0; ; i++) {
        if (collaboratorIdToSnapshotMap.get(peerId)) break
        if (i > 5) {
            try {
                await captureSnapshot()
            } catch (e) {
                console.error(e)
                await timeout(500)
            }
        } else {
            await timeout(500)
        }
    }
}

type ProjectPayload = ProjectUpdateNotification & {
    type: "cocoProjectUpdate"
}

export const currentProjectPayload = atom<ProjectPayload | null>(null)
// The ID of the collaborator whose project is currently being viewed.
export const visitedCollaboratorId = atom<string | null>(null)

export const isVisitingSelf = () => {
    const visitedId = visitedCollaboratorId.get()
    return !visitedId || visitedId === peerRegistry.currentPeerId
}

// The ID of the collaborator whose project is hovered.
export let hoveredProject: string | undefined
export let isLive: boolean = false
export let isProjectLoading = false

// 16KB is max size permitted, we are more conservative
// when sending because
// 1. We add a wrapper over each chunk
// 2. Each chunk is base64 encoded which increases the size
const dataChunkSize = 8000

export const setHoveredProject = (collaborator: string) =>
    (hoveredProject = collaborator)

export const setLiveStatus = (status: boolean) => (isLive = status)

// NOT JSON safe
export interface ProjectData {
    projectId: string
    version: string
    json?: any
    assetIds?: string[]
    previewId?: string
    isTemplate?: boolean
    buffer?: ArrayBufferLike
    abortController?: AbortController
    sizeBytes?: number
    reloadable?: boolean // The version can be reloaded. True if this version has not been loaded before, but data is available now
    loadRequested?: boolean // The version has been requested to be loaded.
    status?: "loading" | "loaded" | "error"
}

export interface PeerProjects {
    currentProjectId: string
    projects: Map</* projectId: */ string, ProjectData>
}

// collaborator id -> project data
export const projectDataMap = new ObservableMap<string, PeerProjects>()


export const getProjectData = (collaboratorId: string): ProjectData | null => {
    const pdEntry = projectDataMap.get(collaboratorId)
    if (!pdEntry) return null
    return pdEntry.projects.get(pdEntry.currentProjectId) ?? null
}

export const setProjectData = (
    collaboratorId: string,
    projectData: ProjectData
) => {
    const pdEntry = projectDataMap.get(collaboratorId) ?? {
        currentProjectId: projectData.projectId,
        projects: new Map<string, ProjectData>(),
    }
    const { projectId } = projectData
    if (pdEntry.currentProjectId !== projectId) {
        pdEntry.currentProjectId = projectId
    }
    pdEntry.projects.set(projectId, projectData)
    projectDataMap.set(collaboratorId, pdEntry)
}

const toProjectUpdateNotification = async (
    collaboratorId: string,
    projectData: ProjectData
): Promise<ProjectUpdateNotification> => ({
    id: collaboratorId,
    projectId: projectData.projectId,
    version: projectData.version,
    sizeBytes: projectData.sizeBytes,
    previewId: projectData.previewId,
    buffer:
        projectData.buffer &&
            projectData.sizeBytes != null &&
            projectData.sizeBytes < projectPeerPullSizeThreshold
            ? arrayBufferToBase64(projectData.buffer)
            : undefined,
})

export const getProjectDataForInitialPayload = async (
    collaboratorId: string
) => {
    let data = getProjectData(collaboratorId)
    if (!data) return undefined
    return toProjectUpdateNotification(collaboratorId, data)
}

export const waitForProjectDataBuffer = async (id: string) => {
    while (true) {
        if (getProjectData(id)?.buffer) break
        console.warn("Project data not yet available. Retrying after 500ms")
        await timeout(500)
    }
}

export const pullProjectFromServer = async (
    id: string,
    projectData: ProjectData,
    params: ProjectUpdateReceiveParams
) => {
    projectData.status = 'loading'
    setProjectData(id, projectData);
    const query = queryString.stringify({
        projectId: projectData.projectId,
        version: projectData?.version,
        spaceId,
        skipAssets: isCodeSpace(),
    });
    if (projectData) {
        projectData.abortController = new AbortController()
    }
    let buffer: ArrayBuffer
    try {
        const resp = await httpClient.get(`/projects/download?${query}`, {
            responseType: "arraybuffer",
            signal: projectData?.abortController?.signal,
            onDownloadProgress: (e) => {
                // 2/3 of the progress is downloading
                Dispatcher.reportProjectLoadingProgress(
                    id,
                    (e.loaded / (e.total || 1)) * (2 / 3) * 100,
                )
            }
        })
        buffer = resp.data
        if (buffer.byteLength === 0) {
            console.error("Received empty buffer")
            return
        }
    } catch (e: any) {
        console.error(`Failed to pull project: ${e.message}`, e)
        if (!projectData.abortController?.signal.aborted) {
            projectData.status = 'error'
            setProjectData(id, projectData)
        }
        return
    }

    onProjectPullSuccess(
        id,
        {
            ...projectData,
            buffer,
        },
        params
    )
}

const onProjectPullSuccess = (
    id: string,
    projectData: ProjectData,
    params: ProjectUpdateReceiveParams
) => {
    if (!params.skipVersionCheck) {
        const awaitedVersion = getProjectData(id)?.version
        if (awaitedVersion && awaitedVersion !== projectData.version) {
            console.log(
                `Expecting project version different from received version`
            )
            return
        }
    }
    projectData.status = 'loaded'
    setProjectData(id, projectData)
    if (id === peerRegistry.currentPeerId) {
        updateCurrentProjectPayload({
            id,
            ...projectData,
            // We need buffer only when we are emitting
            // so skip here
            // when we prepare for emit, we will override this
            buffer: undefined,
        })
    }
    Dispatcher.updatePeerProject(id, omit(projectData, "buffer"))
    if (
        params.isInitial ||
        (id === visitedCollaboratorId.get() &&
            id !== peerRegistry.currentPeerId)
    ) {
        if (isLive || params.isInitial) {
            projectData.reloadable = false
            setProjectData(id, projectData)

            return queueProject({ id, buffer: projectData.buffer! })
        } else {
            projectData.reloadable = true
            setProjectData(id, projectData)
        }
    }
}

const projectPullDebounceThreshold = 1000

const schedulePullProjectFromServer = pullProjectFromServer /*, // debounce(
    projectPullDebounceThreshold
) */

interface ProjectUpdateReceiveParams {
    isInitial: boolean
    skipVersionCheck?: boolean
}

export const replaceWithPeerProject = async (collaboratorId: string) => {
    await pendingEmit?.completePromise
    const currentPeerId = peerRegistry.ensureCurrentPeerId()
    const ownProjectData = getProjectData(currentPeerId)
    let targetProjectData = getProjectData(collaboratorId)
    if (!targetProjectData) {
        return
    }
    if (!targetProjectData.buffer) {
        await pullProjectFromServer(collaboratorId, targetProjectData, {
            isInitial: false,
        })
    }


    targetProjectData = getProjectData(collaboratorId)
    if (!targetProjectData?.buffer) {
        console.warn("Failed to retrieve buffer for project to remix")
        return
    }
    const newProjectData: ProjectData = {
        ...targetProjectData,
        projectId: ownProjectData?.projectId ?? uuid.v4(),
        version: uuid.v4(),
    }
    queue.length = 0
    await onProjectPullSuccess(currentPeerId, newProjectData, {
        isInitial: true,
        skipVersionCheck: true,
    })
    
    await remixComplete(targetProjectData.projectId);

    await handleViewProject(
        peerRegistry.ensureCurrentPeerId(),
    );  

    const notes = vm?.runtime?.notes;
    if (
        notes &&
        notes?.title &&
        !notes?.title.toLowerCase().includes("remix")
    ) {
        vm.runtime.notes = {
            ...notes,
            title: `${notes.title} Remix`,
        }
    }
    

    peerRegistry.updatePeer({ id: currentPeerId, remixedFrom: targetProjectData.projectId });
    return emitProjectInSeq(
        {
            forceFullUpdate: true,
        }
    )
}

export const receiveProjectUpdate = async (
    {
        id,
        projectId,
        previewId,
        version,
        buffer,
        sizeBytes,
        snapshot,
    }: ProjectUpdateNotification,
    params: ProjectUpdateReceiveParams = {
        isInitial: false,
    }
) => {
    if (!params.isInitial && id === peerRegistry.currentPeerId) {
        peerRegistry.markVersionSynced(version)
        return
    }
    peerRegistry.updatePeer({ id, projectId })
    if (snapshot) {
        peerRegistry.updatePeer({ id, image: snapshot })
        collaboratorIdToSnapshotMap.set(id, snapshot)
    }
    const currentProjectData = getProjectData(id)
    if (!params.isInitial && currentProjectData?.version === version) {
        return
    }
    currentProjectData?.abortController?.abort()
    const projectData: ProjectData = {
        projectId,
        version,
        sizeBytes,
        previewId,
        status: undefined,
        loadRequested: currentProjectData?.loadRequested,
        reloadable: currentProjectData?.reloadable,
    }
    if (buffer) {
        const arrayBuffer = base64ToArrayBuffer(buffer)
        if (arrayBuffer) {
            projectData.buffer = arrayBuffer
        }
    }
    setProjectData(id, projectData)
    if (buffer) {
        onProjectPullSuccess(id, projectData, params)
        return
    }
    const peer = peerRegistry.getPeer(id)

    if (params.isInitial || id === visitedCollaboratorId.get()) {
        await pullProjectFromServer(id, projectData, params)
    } else {
        await schedulePullProjectFromServer(id, projectData, params)
    }
}

export const receiveProjectVisitUpdate = async (
    signal: VisitProjectMessage
) => {
    Dispatcher.updateVisitedId(signal.id, signal.visitedId)
}


const deferWhileProjectLoading = async () => {
    while (isProjectLoading) {
        console.warn("Deferring project emit because project is loading")
        await timeout(500)
    }
}

const deferUntilSpaceAssigned = async () => {
    while (!spaceId) {
        console.warn("Deferring project emit because space is not assigned")
        await timeout(500)
    }
}

const _accumulateAssetEntries = (obj: any, entries: ProjectEntry[]) => {
    if (isArray(obj)) {
        for (const item of obj) {
            if (item) {
                _accumulateAssetEntries(item, entries)
            }
        }
        return
    }
    const isObj = isObject(obj) as boolean // Circumvent type guard
    if (!isObj) return
    if (isString(obj.assetId)) {
        return entries.push({
            assetId: obj.assetId,
            hash: obj.assetId,
            filePath: obj.md5ext,
        })
    }
    for (const val of Object.values(obj)) {
        if (val) {
            _accumulateAssetEntries(val, entries)
        }
    }
}

interface ProjectEntry {
    assetId: string
    hash: string
    filePath: string
}

const getAssetEntries = (manifest: any) => {
    if (isString(manifest)) manifest = JSON.parse(manifest)
    const entries: ProjectEntry[] = []
    _accumulateAssetEntries(manifest, entries)
    return sortedUniq(sortBy(entries))
}

interface UploadableEntry {
    key: string
    content: string | Blob
}

const versionCreateDefMapping: Record<
    string,
    Deferred<VersionCreatedMessage>
> = {}

export const receiveVersionCreated = (signal: VersionCreatedMessage) => {
    versionCreateDefMapping[signal.version]?.resolve(signal)
}

export const receiveVersionRejected = (signal: VersionRejectedMessage) => {
    versionCreateDefMapping[signal.version]?.reject(
        new Error("VersionRejected")
    )
}

const emitEntriesToServer = async (
    projectId: string,
    version: string,
    entriesToUpload?: UploadableEntry[],
    entriesToDelete?: string[],
    snapshot?: string,
    parentVersion?: string,
    ensurePreview?: boolean,
    signal?: AbortSignal
): Promise<ProjectUploadOutput> => {
    if (isEmpty(entriesToUpload) && isEmpty(entriesToDelete)) {
        throw new Error("No entries to upload or delete")
    }

    const id = peerRegistry.ensureCurrentPeerId()

    versionCreateDefMapping[version] = new Deferred<VersionCreatedMessage>()

    const all = peerRegistry.getPeerEntries(id).filter((it) => !entriesToDelete?.includes(it));
    const updated = entriesToUpload?.map((it) => it.key)

    CocoLogger.info("rtc-client::patch-update", {
        all,
        updated,
        deleted: entriesToDelete,
    });

    sendWSMessage<CreateVersionMessage>({
        type: "create-version",
        id,
        version,
        parentVersion,
        projectId,
        snapshot,
        entries: {
            all,
            updated,
            deleted: entriesToDelete,
        },
    })

    const versionCreated = (await Promise.race([
        versionCreateDefMapping[version].promise,
        rejectAfter(10_000),
    ])) as VersionCreatedMessage

    CocoLogger.info("rtc-client::version-created", versionCreated);

    if (entriesToUpload) {
        CocoLogger.info("rtc-client::upload-entries", {
            entries: entriesToUpload.map((it) => it.key),
        });
        // We need chunking because s3 fails with 502 error when
        // there are too many concurrent requests
        for (const entryChunk of chunk(entriesToUpload, 3)) {
            await Promise.all(
                entryChunk.map(({ key, content }) =>
                    retryOnErr(async (fail) => {
                        if (signal?.aborted) {
                            // if aborted, just fail early!
                            fail(new Error("canceled"));
                        }
                        const form = new FormData()
                        const dest = versionCreated.uploadUrls[key]
                        Object.entries(dest.fields).forEach(
                            ([field, value]) => {
                                form.append(field, value)
                            }
                        )
                        form.append("file", new Blob([content]), key)
                        await axios.post(dest.url, form, { signal })
                    })
                )
            )
        }
        CocoLogger.info("rtc-client::upload-entries-done");
    }

    CocoLogger.info("rtc-client::promote-version", {
        version,
    });

    await sendWsMessageWithResponse<PromoteVersionMessage>({
        type: "promote-version",
        id: peerRegistry.ensureCurrentPeerId(),
        version,
        projectId,
        ensurePreview,
        messageId: uuid.v4(),
    })

    return {
        version: versionCreated.version,
        previewId: versionCreated.previewId,
    }
}

const emitFullProjectToServer = async (
    projectBlob: Blob,
    snapshot?: string,
    query?: any,
    signal?: AbortSignal
) => {
    CocoLogger.warn("rtc-client::full-update");
    const formData = new FormData()
    if (snapshot) formData.append("snapshot", snapshot)
    formData.append("project", projectBlob)
    const resp = await httpClient.post(
        `/projects/upload?${query ? queryString.stringify(query) : ""}`,
        formData,
        { signal }
    )
    return resp.data
}

const getProjectBlob = () => {
    if (isCodeSpace()) {
        return getCodeArchive()
    } else if (isBlocksSpace()) {
        return vm?.saveProjectSb3()
    } else if (isCanvasSpace()) {
        return getCanvasDataArchive()
    }
    return null
}

const getProjectManifest = (): any => {
    if (isBlocksSpace()) {
        return vm.getManifest()
    }
    if (isCanvasSpace()) {
        return getCanvasManifest()
    }
    // if (isCodeSpace()) {
    //     return getCodeManifest()
    // }
    return null
}

const getRawProjectManifest = (): any => {
    if (isBlocksSpace()) {
        return vm.toJSON()
    }
    if (isCanvasSpace()) {
        return JSON.stringify(getCanvasManifest())
    }
    // if (isCodeSpace()) {
    //     return JSON.stringify(getCodeManifest())
    // }
    return null
}

interface InternalProjectEmitOpts extends ProjectEmitOpts {
    abortController?: AbortController
    captureDeferred?: Deferred<void>
    ensurePreview?: boolean
    forceFullUpdate?: boolean
    failedEntries?: ProjectEmitableEntries
}

const shouldCaptureSnapshotBeforeEmit = (opts?: InternalProjectEmitOpts) => {
    const videoProvider = vm?.runtime?.ioDevices?.video?.provider;
    if (opts?.priority === "low" && isBlocksSpace()) {
        return false
    }

    if (videoProvider && videoProvider.isEnabled()) {
        return false
    }

    return true
}

export const forceSysAdminFullProjectSave = async () => {
    const id = visitedCollaboratorId.get()

    if (!id) {
        throw new Error("Not emitting project because not viewing any user")
    }

    const peer = peerRegistry.getPeer(id)

    if (!peer) {
        throw new Error("Not emitting project because peer not found")
    }

    const projectData = getProjectData(id)
    const projectManifest = getProjectManifest()


    let projectBlob: Blob | undefined
    let entryContents: { key: string; content: string | Blob }[] = []

    const { data, assets } = await getProjectBlob()
    projectBlob = data;


    const snapshot = await attemptSnapshotCapture()

    if (!snapshot) {
        throw new Error("Failed to capture snapshot")
    }


    const parentVersion = projectData?.version
    let version = uuid.v4()

    const query = {
        userName: peer.name,
        peerId: peer.id,
        projectId: peer.projectId,
        ssId: peer.spaceSessionId,
        parentVersion,
        version,
        ensurePreview: false,
        roomId: spaceId,
    }

    const resp = await emitFullProjectToServer(
        projectBlob!,
        snapshot,
        query,
    );

    alert("Project saved successfully");
}

const emitProjectToPeers = async (opts?: InternalProjectEmitOpts) => {
    CocoLogger.info("rtc-client::emit-project-to-peers", opts);
    if (opts?.abortController?.signal?.aborted) {
        return
    }

    const peer = peerRegistry.getCurrentPeer()
    if (!peer || !peer.spaceSessionId) {
        throw new Error(
            "Unable to emit project because space session is not active"
        )
    }
    if (!peer.projectId) {
        throw new Error(
            "Unable to emit project because project id is not yet available"
        )
    }

    await deferWhileProjectLoading()

    const id = visitedCollaboratorId.get()

    if (id !== peer.id) {
        throw new Error("Not emitting project because viewing another user")
    }

    let projectManifest: any
    let projectData: ProjectData | null

    if (shouldCaptureSnapshotBeforeEmit(opts)) {
        // We don't hide video here to prevent flickering
        await captureSnapshotOrIgnore()
    }

    await deferUntilSpaceAssigned()

    if (opts?.abortController?.signal?.aborted) {
        throw new Error("emit canceled")
    }

    projectData = getProjectData(id)
    projectManifest = getProjectManifest()

    let assetIds: string[] | undefined

    let projectBlob: Blob | undefined
    let entryContents: { key: string; content: string | Blob }[] = []

    const parentVersion = projectData?.version

    let needsFullUpdate = true
    let entries = opts?.entries

    let prevAssetIds = projectData?.assetIds
    assetIds = getAssetEntries(projectManifest).map((it) => it.assetId)
    if (prevAssetIds && isEqual(assetIds, prevAssetIds) && !opts?.forceFullUpdate) {
        needsFullUpdate = false
    }

    if (!entries && parentVersion) {
        entries = { updated: [], deleted: [] }
        if (peer.syncState?.entries) {
            for (const [filePath, entrySyncState] of Object.entries(
                peer.syncState.entries
            )) {
                if (entrySyncState.locallyDeletedAt && !assetIds.includes(filePath.split(".")[0])) {
                    entries.deleted?.push(filePath)
                } else if (entrySyncState.locallyUpdatedAt) {
                    entries.updated?.push(filePath)
                }
            }
        }
        CocoLogger.info("rtc-client::emit-project-to-peers::compute_entries", entries);
    }
    const entriesTrackedAt = +new Date()

    if (entries && parentVersion && !opts?.forceFullUpdate) {
        needsFullUpdate = false
    }

    let allAssets: any;

    if (needsFullUpdate) {
        const { data, assets } = await getProjectBlob()
        allAssets = assets;
        projectBlob = data;
    } else if (opts?.failedEntries) {
        if (entries) {
            entries = {
                updated: (entries.updated ?? []).concat(
                    opts.failedEntries.updated ?? []
                ),
                deleted: (entries.deleted ?? []).concat(
                    opts.failedEntries.deleted ?? []
                ),
            }
        } else {
            entries = opts.failedEntries
        }
    }

    if (pendingEmit) {
        pendingEmit.isFullUpdate = needsFullUpdate
        pendingEmit.entries = entries
    }

    if (visitedCollaboratorId.get() !== peerRegistry.currentPeerId) {
        throw new Error("Not emitting project because viewing another user")
    }


    if (entries?.updated) {
        if (isCanvasSpace()) {
            for (const key of entries.updated) {
                const content =
                    key === projectFileKey
                        ? getRawProjectManifest()
                        : canvasEditorStore.getFrameContent(key)
                if (!content) continue
                entryContents.push({ key, content })
            }
        } else if (isCodeSpace()) {
            entryContents.push(
                ...compact(
                    codeEditorStore.state.get().files.map((f) => {
                        if (entries!.updated!.indexOf(f.path) < 0) {
                            return null
                        }
                        if (f.content) {
                            return { key: f.path, content: f.content }
                        }
                    })
                )
            )
        } else if (isBlocksSpace()) {
            // Always retain the manifest
            entryContents.push({
                key: projectFileKey,
                content: JSON.stringify(projectManifest),
            })
            const updatedSet = new Set(entries.updated)
            vm.getAssets(updatedSet).forEach((it: any) => {
                entryContents.push({
                    key: it.fileName,
                    content: it.fileContent,
                })
            })
        }
    }

    const buffer = await projectBlob?.arrayBuffer()
    if (projectData && buffer) {
        // Update projectDataMap preemptively so that if we switch to
        // different project and revisit own we should get the updated
        // project (even if its pending save) and not last saved buffer
        projectData.buffer = buffer
    }

    // Ensure that project wasn't switched in the meanwhile
    if (visitedCollaboratorId.get() !== peer.id) {
        throw new Error("Not emitting project because viewing another user")
    }
    opts?.captureDeferred?.resolve()

    // We should not rely on vm or other state atoms beyond this point
    // because user may have switched project

    if (opts?.abortController?.signal?.aborted) {
        throw new Error("emit canceled")
    }

    let didNotify = false
    let version = uuid.v4()

    if (entries) {
        peerRegistry.markVersionAwaited(version, entries, entriesTrackedAt)
    }

    // Small blobs are directly emitted to peers
    if (
        projectBlob &&
        (!supportProjectSaveToServer ||
            projectBlob.size < projectPeerPullSizeThreshold)
    ) {
        if (peerRegistry.currentPeerId) {
            setProjectData(peer.id, {
                projectId: z.string().parse(peer.projectId),
                version: z.string().parse(version),
                sizeBytes: projectBlob.size,
                buffer,
                json: projectManifest,
            })
        }
        // Failure is acceptable here, if this fails we don't care
        // becase the update will be received over websocket after
        // upload anyways
        shareProjectUpdate({
            id: peer.id,
            version,
            sizeBytes: projectBlob.size,
            buffer: arrayBufferToBase64(buffer!),
        }).catch((e) => {
            console.error("Failed to share project update via data channel", e)
        })
        didNotify = true
    }

    let sizeBytes: number | null | undefined
    let previewId: string | null | undefined

    let resp: any
    let lastErr: any

    if (supportProjectSaveToServer) {
        const saveTimeoutMs = needsFullUpdate ? FULL_UPDATE_TIMEOUT : PATCH_TIMEOUT;
        await Promise.race([
            rejectAfter(saveTimeoutMs),
            (async () => {

                // Try saving with retries
                const query = {
                    userName: peer.name,
                    peerId: peer.id,
                    projectId: peer.projectId,
                    ssId: peer.spaceSessionId,
                    parentVersion,
                    version,
                    ensurePreview: opts?.ensurePreview,
                    roomId: spaceId,
                }
                const snapshot = await getLastSnapshot(
                    peerRegistry.ensureCurrentPeerId()
                )
                if (opts?.abortController?.signal?.aborted) {
                    throw new Error("emit canceled")
                }
                Dispatcher.startSavingProject()
                for (let i = 0; i < maxUploadAttempts; i++) {
                    lastErr = undefined
                    if (opts?.abortController?.signal?.aborted) {
                        throw new Error("emit canceled")
                    }
                    try {
                        if (needsFullUpdate) {
                            resp = await emitFullProjectToServer(
                                projectBlob!,
                                snapshot,
                                query,
                                opts?.abortController?.signal
                            )
                        } else {
                            resp = await emitEntriesToServer(
                                peer.projectId as string,
                                version,
                                entryContents,
                                entries?.deleted,
                                snapshot,
                                parentVersion,
                                opts?.ensurePreview,
                                opts?.abortController?.signal
                            )
                        }
                        ; ({ version, sizeBytes, previewId } = resp)
                        if (version === parentVersion) {
                            return // Nothing has changed really
                        }

                        CocoLogger.info("rtc-client::emit-project-to-peers::success", {
                            version,
                            sizeBytes,
                            previewId,
                        });
                        const projectUpdate = {
                            id: peer.id,
                            version: z.string().parse(version),
                            previewId: previewId ?? undefined,
                            sizeBytes: sizeBytes ?? projectBlob?.size,
                        }
                        if (!didNotify) {
                            // Intentionally fire-and-forget, refer above
                            shareProjectUpdate(projectUpdate).catch((e) => {
                                console.error(
                                    "Failed to share project update via data channel",
                                    e
                                )
                            })
                        } else {
                            // This is needed to ensure that next call to server
                            // does not get sent with parent version that was
                            // locally generated
                            updateCurrentProjectPayload(projectUpdate)
                        }
                        break
                    } catch (e: any) {
                        // Since cancelled is an expected error, just emit a warn for it,
                        // so we don't trigger any alerts for no reason
                        if (e.message?.match(/canceled/)) {
                            CocoLogger.warn("rtc-client::emit-projects-to-peer::warn", {
                                message: e.message,
                            });
                        } else {
                            CocoLogger.error("rtc-client::emit-projects-to-peer::error", {
                                message: e.message,
                            });
                        }
                        if (
                            e.message?.match(/canceled/) ||
                            e.message?.match(/VersionRejected/)
                        ) {
                            throw e // do not reattempt
                        } else {
                            lastErr = e
                        }
                    }
                }
            })(),
        ]);


        Dispatcher.endSavingProject(!lastErr)
        if (lastErr) {
            throw lastErr
        }

        if (needsFullUpdate) {
            peerRegistry.updateSyncState(peerRegistry.currentPeerId, (prev) => {
                const next = {
                    ...prev,
                    entries: {
                    },
                } as any;

                for (const asset of allAssets) {
                    next.entries[asset.fileName] = {
                        hash: asset.fileName.split('.')[0],
                    }
                }
                return next
            })
        } else if (entries) {
            peerRegistry.updateSyncState(peerRegistry.currentPeerId, (prev) => {
                const next = {
                    ...prev,
                    entries: {
                        ...prev?.entries,
                    },
                }
                if (entries?.updated) {
                    for (const assetPath of entries.updated) {
                        const luAt = next.entries[assetPath]?.locallyUpdatedAt
                        if (luAt && luAt < entriesTrackedAt) {
                            next.entries[assetPath] = {
                                ...next.entries[assetPath],
                                locallyUpdatedAt: undefined,
                            }
                        }
                    }
                }
                if (entries?.deleted) {
                    for (const assetPath of entries.deleted) {
                        delete next.entries[assetPath]
                    }
                }
                return next
            })
        }
    }

    if (peerRegistry.currentPeerId) {
        setProjectData(peerRegistry.currentPeerId, {
            projectId: z.string().parse(peer.projectId),
            version: z.string().parse(version),
            sizeBytes: sizeBytes ?? projectBlob?.size,
            buffer,
            assetIds,
            json: projectManifest,
        })
    }
}

interface ProjectEmitHandle {
    // Priority of emit attempt - currently affects only
    // the wait duration before sync is attempted
    //
    // Changing this after emit has been initiated is noop
    priority: ProjectEmitPriority
    // Can be used to short circuit the waiting time
    // between project emit
    timeoutDeferred?: Deferred<void>
    captureCompletePromise: Promise<void>
    // Used for sequencing of emit attempts
    // Can be used to wait for completion of emit attempt
    // Does not ever reject
    completePromise?: Promise<void>
    // Can be used to externally abort the emit attempt
    abortController?: AbortController
    didFail?: boolean
    isFullUpdate?: boolean
    entries?: ProjectEmitableEntries
    preventNextPartial?: boolean
}

export let pendingEmit: ProjectEmitHandle | undefined
export let pendingSaveProject: Deferred<void> | undefined

// How much should a pending emit be delayed
//
// (primary intent is to reduce load on server)
const getEmitDelay = (
    didLastFail: boolean,
    parentVersion?: string,
    opts?: ProjectEmitOpts
) => {
    // Immediately emit if previous emit failed
    if (didLastFail || !parentVersion) return 0

    // If a delay is explicitly provided, respect that
    if (!isNil(opts?.delayMS)) return opts!.delayMS

    // Decide based on priority
    if (opts?.priority === "high") return 2_000

    return 3_000
}

const deferEmitByDuration = (
    emitHandle: ProjectEmitHandle,
    durationMS: number
) => {
    const timeoutDeferred = new Deferred<void>()
    emitHandle.timeoutDeferred = timeoutDeferred
    setTimeout(() => {
        if (timeoutDeferred.didResolve) {
            console.log("[pdc] Emit timeout resolved ahead of time")
        }
        timeoutDeferred.resolve()
        emitHandle.timeoutDeferred = undefined
    }, durationMS)
    return timeoutDeferred.promise
}

let projectReattemptDurationMS = 1000
let projectReattemptTimer: any

// We need to sequence the uploads so that server
// always knows which is the latest version uploaded
// otherwise it can end up notifying other clients about older versions
const emitProjectInSeq = async (opts?: ProjectEmitOpts) => {
    clearTimeout(projectReattemptTimer)

    let priority = opts?.priority ?? "high"
    const didLastFail = pendingEmit?.didFail ?? false
    let forceFullUpdate = opts?.forceFullUpdate || false
    let failedEntries: ProjectEmitableEntries = {}
    if (didLastFail) {
        priority = "high"
        failedEntries = pendingEmit?.entries ?? {}
    }



    if (pendingEmit && !didLastFail && pendingEmit.timeoutDeferred) {
        // Emit is enqueued but not yet begun
        if (priority === "high" && pendingEmit.priority !== "high") {
            // Fast forward the pending emit
            pendingEmit.timeoutDeferred.resolve()
        }
        if (pendingEmit.entries) {
            pendingEmit.entries = mergeEmittableEntries(
                pendingEmit.entries,
                opts?.entries
            )
        }
        return // in favor of the pending emit
    }

    if (pendingEmit?.isFullUpdate || pendingEmit?.preventNextPartial) {
        // Either we are interupting an ongoing full update
        // or a full update failed - thus we need to do a full update
        // otherwise we can end up with a manifest with missing entries
        forceFullUpdate = true
    }

    if (!pendingEmit || didLastFail) {
        Dispatcher.markProjectDirty()
    }

    let delay = getEmitDelay(
        didLastFail,
        getProjectData(peerRegistry.ensureCurrentPeerId())?.version,
        opts
    )
    // All requests should happen in sequence
    // But a low priority request
    // should not delay a higher priority update
    if ((pendingEmit && pendingEmit?.priority !== "high") || priority === "high") {
        pendingEmit?.abortController?.abort()

        // Prevent accumulation of delays
        delay = 0
    }

    const prevP = pendingEmit?.completePromise

    // When resolved we'd have extracted all that we need from
    // current project so project switching will be enabled
    const captureDeferred = new Deferred<void>()

    const emitHandle: ProjectEmitHandle = {
        priority,
        captureCompletePromise: captureDeferred.promise,
        abortController: new AbortController(),
    }

    pendingEmit = emitHandle

    if (prevP) {
        await prevP;
    }

    emitHandle.completePromise = deferEmitByDuration(emitHandle, delay)
        .then(async () => {
            await emitProjectToPeers({
                abortController: emitHandle.abortController,
                captureDeferred,
                ...opts,
                forceFullUpdate,
                failedEntries,
            });
            pendingEmit = undefined // Only if emit succeeds
            projectReattemptDurationMS = 1000 // Reset reattempt duration
        })
        .catch((e) => {
            // Let's suppress when the upload is canceled
            // because another upload is initiated parallely
            if (e.message?.match(/canceled/i)) {
                return
            }
            if (pendingEmit) {
                pendingEmit.didFail = true
                if (e.message?.match(/VersionRejected/)) {
                    // This mostly happens because server
                    // does not have all the entries that it should have
                    // after partial update - so enforce that the next
                    // emit is a full emit
                    pendingEmit.preventNextPartial = true
                }
            }

            // Schedule a reattempt
            projectReattemptTimer = setTimeout(() => {
                emitProjectInSeq(opts)
            }, projectReattemptDurationMS)

            // Increase delay for subsequent reattempt
            projectReattemptDurationMS *= 2
        })
        .then(() => {
            // Resolve deferreds in case of early returns/failures
            // in the promise chain
            emitHandle?.timeoutDeferred?.resolve()
            captureDeferred.resolve()
        })
    return emitHandle.completePromise
}

export type ProjectEmitPriority = "low" | "high"

export interface ProjectEmitableEntries {
    updated?: string[]
    deleted?: string[]
}

const mergeEmittableEntries = (
    entries?: ProjectEmitableEntries,
    nextEntries?: ProjectEmitableEntries
) => {
    const updated = new Set<string>()
    const deleted = new Set<string>()
    entries?.updated?.forEach((it) => updated.add(it))
    entries?.deleted?.forEach((it) => deleted.add(it))
    nextEntries?.updated?.forEach((it) => updated.add(it))
    nextEntries?.deleted?.forEach((it) => deleted.add(it))
    return {
        updated: [...updated],
        deleted: [...deleted],
    }
}

export interface ProjectEmitOpts {
    // Indicates how important it is to emit wrt. impact of data loss

    // eg. if a block is moved within workspace
    // it is a low priority req, but if a sprite is added
    // it has higher priority
    priority?: ProjectEmitPriority

    // Explicitly specify the emit delay - otherwise inferred from priority
    delayMS?: number

    // If remote preview should be generated - only relevant for p5 space right now
    ensurePreview?: boolean

    // Entries to emit
    // For blocks space this can be omitted as we track individual asset sync status
    //
    // TODO make this unnecessary for all space types
    entries?: ProjectEmitableEntries


    forceFullUpdate?: boolean
}

// Mark that there is a emit enqueued
//
// An enqueued emit will be only emitted if the project is not replaced
// by a past project
export let isEmitEnqueued = false

export const emitProject = async (opts?: ProjectEmitOpts) => {
    if (
        visitedCollaboratorId.get() &&
        visitedCollaboratorId.get() !== peerRegistry.currentPeerId
    ) {
        return
    }
    if (isProjectLoading) {
        return
    }
    if (
        !peerRegistry.currentPeerId ||
        !initialProjectDetailsDeferred.didResolve
    ) {
        // Emit only if initial project details have been fetched
        // because the default project will be replaced if user
        // had a past project
        isEmitEnqueued = true
    } else {
        isEmitEnqueued = false
        return emitProjectInSeq(opts)
    }
}

// Convenience method to emit without any delay - eg. when the space
// ends and it is important to save the project right then
export const emitProjectImmediately = async (opts?: ProjectEmitOpts) => {
    await initialProjectDetailsDeferred.promise
    return emitProjectInSeq({
        priority: "high",
        delayMS: 0,
        ...opts,
    })
}

const concatenateDataChunks = (chunksArray: ArrayBufferLike[]) => {
    let length = chunksArray.reduce((acc, value) => acc + value.byteLength, 0)
    let buffer = new Uint8Array(length)
    let offset = 0
    chunksArray.forEach((chunk) => {
        buffer.set(new Uint8Array(chunk), offset)
        offset += new Uint8Array(chunk).byteLength
    })
    return buffer
}

const updateCurrentProjectPayload = (
    update: Omit<ProjectUpdateNotification, "parentVersion" | "projectId">
) => {
    const parentVersion = currentProjectPayload.get()?.version
    currentProjectPayload.set(
        DataChannelMessages.cocoProjectUpdate({
            ...update,
            buffer: undefined,
            projectId: peerRegistry.ensureCurrentPeerId(),
            parentVersion,
        }) as ProjectPayload
    )
}

const shareProjectUpdate = async (
    update: Omit<ProjectUpdateNotification, "parentVersion" | "projectId">
) => {
    updateCurrentProjectPayload(update)
}


interface ProjectQueueItemInput {
    id: string
    buffer: ArrayBuffer
    lastSpriteName?: string
}

interface ProjectQueueItem extends ProjectQueueItemInput {
    deferred: Deferred<void>
}

const queue: ProjectQueueItem[] = []

// Project loading is async, so we use a queue to ensure they
// load sequentially (if the VM attempts to load a new project
// before the previous finishes loading, data duplication can occur).
//
// Useful for situations such as when the user attempts to view one
// collaborator's project immediately after another, or if we want to
// see a collaborator's changes in real-time (though this is not the
// current behavior).
const queueProject = (member: ProjectQueueItemInput) => {
    const item = { ...member, deferred: new Deferred<void>() }
    if (isProjectLoading) {
        queue.push(item)
    } else {
        loadNext(item)
    }
    return item.deferred.promise
}

const loadNext = async (projQItem: ProjectQueueItem): Promise<void> => {
    const { buffer, lastSpriteName, deferred, id } = projQItem
    let projectData: ProjectData | null = null
    try {
        projectData = getProjectData(id)
        await pendingEmit?.captureCompletePromise
        await pendingSaveProject?.promise;
        isProjectLoading = true
        if (isBlocksSpace()) {
            vm.runtime.coco.projectOwnerId = id
            await vm.loadProject(buffer)
        } else if (isCodeSpace()) {
            try {
                await loadCodeArchive(buffer)
            } catch (e) {
                console.error("Failed to parse project buffer as code: ", e)
            }
        } else if (isCanvasSpace()) {
            try {
                await loadCanvasDataArchive(buffer)
            } catch (e) {
                console.error("Failed to parse project buffer as image: ", e)
            }
        }
        // Project had loaded. Clear interval and make progress 100

        deferred.resolve()
        await timeout(0)
        if (queue.length > 0) {

            return loadNext(queue.shift()!)
        }
        // Project loading is async, so use timer.
        // Check queue again in case new project changes were pushed.
        await captureSnapshotOrIgnore({
            broadcast: isCodeSpace(),
            hideVideo: true,
        })

        isProjectLoading = false
        // start stream after project has loaded
        const peerId =
            visitedCollaboratorId.get() ?? peerRegistry.ensureCurrentPeerId()
        if (vm) {
            try {
                const manifest = vm.getManifest()
                const entries = getAssetEntries(manifest)
                const assetIds = entries.map((it) => it.assetId)
                peerRegistry.updateSyncState(peerId, (prev) => {
                    const next = {
                        ...prev,
                        entries: {
                            ...prev?.entries,
                        },
                    }
                    for (const entry of entries) {
                        next.entries[entry.filePath] ??= {
                            hash: entry.assetId, // assetId is md5 hash in case of scratch blocks
                        }
                    }
                    return next
                })
                if (projectData) {
                    projectData.json = manifest
                    // Backward compat - TODO Remove
                    projectData.assetIds = assetIds
                }
            } catch (e) {
                console.error("Failed to capture json from vm: ", e)
            }
        }

        actionEmitter.post({
            type: "scratch-gui/project-state/DONE_LOADING_VM_WITHOUT_ID",
        })
        Dispatcher.unmarkProjectDirty()
        //set back editing target to last selected sprite
        if (lastSpriteName && vm) {
            let id = vm.runtime.targets.find(
                (target: any) => target.sprite.name === lastSpriteName
            )?.id
            if (id) vm.setEditingTarget(id)
        }

    } catch (err: any) {
        console.error(err)
        isProjectLoading = false
        CocoLogger.error("Failed to load project with error", err);
        const didConfirm = confirm("Failed to load project. Retry ?")
        if (didConfirm) {
            if (id === peerRegistry.currentPeerId && projectData?.version) {
                await httpClient.delete(
                    `/projects/${projectData.version}/discard`
                )
                // Full refresh is currently needed because vm may be left in an inconsistent state
                location.reload()
                return
            }
            loadNext(projQItem)
        }
    }
}

export const broadcastVisitedProjectMessage = (visitedId: string) => {
    const currentPeerId = peerRegistry.ensureCurrentPeerId()
    const visitProjectMessage: VisitProjectMessage = {
        type: "visit-project",
        id: currentPeerId,
        visitedId,
    }
    sendWSMessage(visitProjectMessage).catch((e) => {
        console.error("Failed to send ws message", e)
    })
}

let lastSpriteName: string | undefined

let previousLoaderState = {
    superseded: false,
};

export const handleCancelViewProject = () => {
    if (lastSuccessfullCollaboratorViewed) {
        handleViewProject(lastSuccessfullCollaboratorViewed, true)
    } else {
        handleViewProject(peerRegistry.ensureCurrentPeerId(), true)
    }
}


// onClick handler for selecting a collaborator to view.
export const handleViewProject = async (
    peerId: string,
    forceEnqueue = false,
    reloading = false,
) => {

    const thisLoaderState = {
        superseded: false,
    };

    if (previousLoaderState) {
        previousLoaderState.superseded = true;
    }

    previousLoaderState = thisLoaderState;

    Dispatcher.reportProjectLoadingProgress(peerId, 0);
    Dispatcher.switchingProject(peerId, reloading)
    await initialProjectDetailsDeferred.promise

    const currentPeerId = peerRegistry.ensureCurrentPeerId()
    if (visitedCollaboratorId.get() === currentPeerId) {
        // Visiting own project which may have unsaved changes
        pendingEmit?.timeoutDeferred?.resolve()
        await pendingEmit?.captureCompletePromise

        if (!pendingEmit?.isFullUpdate && pendingSaveProject === undefined && !isProjectLoading) {
            // If full update is pending, project will be
            // captured as part of that, otherwise we need to
            // capture it so that we can prevent getting a flicker
            // or last saved state when switching back
            const projectData = getProjectData(currentPeerId)
            if (projectData) {
                pendingSaveProject = new Deferred();
                try {
                    await captureSnapshotOrIgnore({
                        broadcast: true,
                        hideVideo: true,
                    })
                    const { data } = (await getProjectBlob())
                    const buffer = await data?.arrayBuffer()
                    if (buffer) projectData.buffer = buffer
                    pendingSaveProject?.resolve();
                } catch (err) {
                    pendingSaveProject?.resolve();
                }
                pendingSaveProject = undefined;

            } else {
                console.warn("Own project data missing")
            }
        }
    }

    Dispatcher.updateVisitedId(currentPeerId, peerId)
    broadcastVisitedProjectMessage(peerId)


    // if not clicked your own project
    const currentlyViewing = visitedCollaboratorId.get()
    if (
        !(
            currentPeerId === currentlyViewing &&
            currentPeerId === peerId
        ) ||
        forceEnqueue
    ) {
        if (vm && currentlyViewing === currentPeerId) {
            // Lets attempt to preserve the sprite the user
            // is currently viewing
            lastSpriteName = vm.editingTarget?.sprite?.name
        }

        // broadcast the latest mini-stage snapshot
        // shareDataInChunks('image', base64ToArrayBuffer(btoa(images.get(peerRegistry.currentPeerId))));
        // Dispatch.setImage(peerRegistry.currentPeerId, images.get(peerRegistry.currentPeerId));

        // load collaborator's project
        let projectData = getProjectData(peerId)
        if (!projectData) {
            console.error(
                "Attempting to view project but project data not available"
            )
            return
        }
        try {
            Dispatcher.updateProjectLoadStatus(peerId, true)
            if (!projectData?.buffer) {
                projectData.loadRequested = true;
                setProjectData(peerId, projectData);
                await pullProjectFromServer(peerId, projectData!, {
                    isInitial: false,
                })
            }
            await waitForProjectDataBuffer(peerId)
        } finally {
            Dispatcher.updateProjectLoadStatus(peerId, false)
        }
        projectData = getProjectData(peerId)
        if (!projectData?.buffer) {
            console.error("Project data could not be loaded")
            return
        }

        // Only enqueue if we are still viewing the same collaborator
        if (!thisLoaderState.superseded) {
            projectData.loadRequested = false
            setProjectData(peerId, projectData)

            Dispatcher.reportProjectLoadingProgress(peerId, (2 / 3) * 100);
            await queueProject({
                buffer: projectData.buffer,
                id: peerId,
                lastSpriteName:
                    peerId === currentPeerId ? lastSpriteName : undefined,
            })

            Dispatcher.reportProjectLoadingProgress(peerId, 100);
            Dispatcher.viewingProjectReloadable(false)
            visitedCollaboratorId.set(peerId)
            Dispatcher.viewCollaborator(peerId)
            lastSuccessfullCollaboratorViewed = peerId;

            // Refetch the project data in case it was updated
            projectData = getProjectData(peerId) as ProjectData
            projectData.reloadable = false
        } else {
            projectData.reloadable = true
        }

        setProjectData(peerId, projectData)
        // pause the stream
        //setLocalStreamStatus(false);
    }

    if (!thisLoaderState.superseded) {
        setTimeout(() => {
            Dispatcher.switchingProject(null)
            Dispatcher.reportProjectLoadingProgress(peerId, 100);
        }, 200)
    }
}

export interface ProjectPullConsolidatedResponse extends ProjectData {
    requestId: string
    buffer: ArrayBufferLike
}

interface ProjectPullRequestHandle {
    id: string
    params: ProjectUpdateReceiveParams
    resolve: (resp: ProjectPullConsolidatedResponse) => void
    version?: string
    sizeBytes?: number
    chunks: ArrayBuffer[]
}

const pendingProjectPullRequests = new Map<string, ProjectPullRequestHandle>()

export const onProjectPullResponse = async (message: ProjectPullResponse) => {
    let handle = pendingProjectPullRequests.get(message.requestId)
    if (!handle) {
        console.error(
            `Received response for unanticipated project pull: ${message.requestId}`
        )
        return
    }
    if (handle.version && handle.version !== message.version) {
        throw new Error(
            `Version changed mid-way will receiving project pull: ${handle.version} -> ${message.version}`
        )
    }
    handle.version = message.version
    if (isNumber(message.sizeBytes)) {
        handle.sizeBytes = message.sizeBytes
    }
    const chunkBuffer = base64ToArrayBuffer(message.chunk)
    if (chunkBuffer) handle.chunks.push(chunkBuffer)
    if (message.isComplete) {
        const concatenated = concatenateDataChunks(handle.chunks)
        pendingProjectPullRequests.delete(message.requestId)
        const consolidatedResp = {
            buffer: concatenated,
            projectId: message.projectId,
            sizeBytes: z.number().parse(handle.sizeBytes),
            version: z.string().parse(handle.version),
        }
        handle.resolve({
            requestId: message.requestId,
            ...consolidatedResp,
        })
        onProjectPullSuccess(handle.id, consolidatedResp, handle.params)
    }
}
