import { Trans } from '@lingui/macro';
import { useCommunityDetails } from 'atoms/community-details';
import { useCommunitySummaries } from 'atoms/community-summaries';
import { DashboardParams } from 'constants/routes';
import React, { useEffect, useMemo, useRef } from 'react';
import ReactJoyride, { ACTIONS, EVENTS, STATUS, Step } from 'react-joyride';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import {
  GETTING_STARTED_TUTORIAL,
  getGettingStartedSteps,
  ADD_MEMBERS_TUTORIAL,
  getAddMembersSteps,
} from './getting-started';
import { INTERACTIONS, useTrack } from 'utils/mixpanel';
import { EVENTS as AEvents } from 'utils/mixpanel';
import { useUserProfile } from 'atoms/user-profile';

const activeTutorial = atom({
  key: 'activeTutorial',
  default: {
    name: '' as typeof GETTING_STARTED_TUTORIAL | '',
    steps: [] as Step[],
    run: false,
    stepIndex: 0,
  },
});

const ADD_MEMBERS_LOCAL_STORAGE_KEY = 'ADD_MEMBERS_TUTORIAL';

export function JoyrideWrapper() {
  const tutorial = useTutorial();
  const track = useTrack();

  const location = useLocation();
  const { userProfile, updateUserProfile } = useUserProfile();

  const { communities } = useCommunitySummaries({
    autoLoad: false,
  });

  const joinedCommunities = useMemo(
    () => communities?.find((c) => c?.currentUserRoles?.length > 0),
    [communities],
  );

  const { community } = useCommunityDetails({
    communityId: joinedCommunities?.id,
    autoLoad: false,
  });

  const tutorialStarted = useRef(false);

  useEffect(() => {
    if (
      userProfile &&
      !userProfile?.tutorialsCompleted?.includes(GETTING_STARTED_TUTORIAL) &&
      location.pathname?.includes('/app/dashboard') &&
      joinedCommunities &&
      !tutorialStarted.current
    ) {
      tutorialStarted.current = true;
      tutorial.startGettingStartedTutorial();
      updateUserProfile({
        tutorialsCompleted: [
          ...(userProfile?.tutorialsCompleted || []),
          GETTING_STARTED_TUTORIAL,
        ],
      });

      // set add members timestamp to now
      localStorage.setItem(
        ADD_MEMBERS_LOCAL_STORAGE_KEY,
        new Date().toISOString(),
      );
    } else {
      // check if we should show the add members tutorial
      let addMembersTutorialTimestamp = localStorage.getItem(
        ADD_MEMBERS_LOCAL_STORAGE_KEY,
      );

      if (!addMembersTutorialTimestamp) {
        // set add members timestamp to now
        addMembersTutorialTimestamp = new Date().toISOString();
        localStorage.setItem(
          ADD_MEMBERS_LOCAL_STORAGE_KEY,
          addMembersTutorialTimestamp,
        );
      }

      if (community?.members?.length === 1) {
        const addMembersTutorialDate = new Date(addMembersTutorialTimestamp);
        const now = new Date();
        const diff = now.getTime() - addMembersTutorialDate.getTime();
        const diffDays = diff / (1000 * 3600 * 24);

        if (diffDays > 1) {
          tutorial.startAddMembersTutorial();
          // set add members timestamp to now
          localStorage.setItem(
            ADD_MEMBERS_LOCAL_STORAGE_KEY,
            new Date().toISOString(),
          );
        }
      }
    }
  }, [location.pathname, joinedCommunities, userProfile, tutorial]);

  return (
    <ReactJoyride
      steps={tutorial.steps}
      run={tutorial.run}
      stepIndex={tutorial.stepIndex}
      callback={(data) => {
        const { action, index, status, type } = data;

        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
          if (status === STATUS.FINISHED) {
            track(AEvents.TUTORIAL_FINISH, INTERACTIONS.CLICK, {
              tutorial: tutorial.name,
            });
          } else if (status === STATUS.SKIPPED) {
            track(AEvents.TUTORIAL_SKIP, INTERACTIONS.CLICK, {
              tutorial: tutorial.name,
            });
          } else {
            track(AEvents.TUTORIAL_CLOSE, INTERACTIONS.CLICK, {
              tutorial: tutorial.name,
            });
          }
          // Need to set our running state to false, so we can restart if we click start again.
          tutorial.stopTutorial();
        } else if (
          ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(
            type,
          )
        ) {
          if (action === ACTIONS.NEXT) {
            track(AEvents.TUTORIAL_NEXT, INTERACTIONS.CLICK, {
              tutorial: tutorial.name,
              step: index,
            });
          } else if (action === ACTIONS.PREV) {
            track(AEvents.TUTORIAL_PREVIOUS, INTERACTIONS.CLICK, {
              tutorial: tutorial.name,
              step: index,
            });
          }

          const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
          console.log('nextStepIndex', nextStepIndex);
          tutorial.setStepIndex(nextStepIndex);
        }
      }}
      styles={{
        buttonNext: {
          backgroundColor: 'var(--color-coco-pink)',
        },
        buttonBack: {
          color: 'var(--color-text)',
        },
      }}
      locale={{
        skip: <Trans>Skip</Trans>,
        next: <Trans>Next</Trans>,
        back: <Trans>Back</Trans>,
      }}
      continuous
      hideCloseButton={true}
      showProgress={false}
    />
  );
}

export function useTutorial() {
  const [active, setActive] = useRecoilState(activeTutorial);
  const { trigger } = useCommunitySummaries({
    autoLoad: false,
  });

  const { userProfile } = useUserProfile();
  const isEducationalUser =
    userProfile?.userType === 'EDUCATIONAL' ||
    userProfile?.educationalAccessApproved;

  const history = useHistory();

  const track = useTrack();

  function startTutorial(name: string, steps: Step[]) {
    setActive({ steps, run: true, stepIndex: 0, name });
  }

  function stopTutorial() {
    setActive({ steps: [], run: false, stepIndex: 0, name: '' });
  }

  function setStepIndex(stepIndex: number) {
    console.log('setStepIndex', stepIndex);
    setActive({ ...active, stepIndex });
  }

  async function startGettingStartedTutorial(communityId?: string) {
    track(AEvents.TUTORIAL_START, INTERACTIONS.CLICK, {
      tutorial: GETTING_STARTED_TUTORIAL,
    });
    const resp: any = await trigger();

    const communities = resp.data;
    const community =
      communityId && communityId !== '$all'
        ? communities.find((c) => c.id === communityId)
        : communities[0];

    history.push(`/app/dashboard/${community?.id}`);

    const canManageUsers =
      community?.currentUserRoles?.includes('OWNER') ||
      community?.currentUserRoles?.includes('MANAGER');

    const steps = getGettingStartedSteps(
      canManageUsers,
      isEducationalUser,
      communityId,
      community?.corridorType === 'EDUCATIONAL',
    );
    startTutorial(GETTING_STARTED_TUTORIAL, steps);
  }

  async function startAddMembersTutorial() {
    track(AEvents.TUTORIAL_START, INTERACTIONS.CLICK, {
      tutorial: ADD_MEMBERS_TUTORIAL,
    });

    const steps = getAddMembersSteps(isEducationalUser);
    startTutorial(ADD_MEMBERS_TUTORIAL, steps);
  }

  return {
    stopTutorial,
    setStepIndex,
    startGettingStartedTutorial,
    startAddMembersTutorial,
    ...active,
  };
}
